import { breakpoints, device } from 'src/styles/breakpoints'
import styled from 'styled-components'

export const ContentHeader = styled.section`
  position: relative;
  min-height: 598px;
  h1 {
    font-family: "Sora", Helvetica, sans-serif;
  }

  @media ${device.tablet} {
    min-height: 416px;
  }

  @media ${device.desktopLG} {
    min-height: 504px;
  }

  @media ${device.desktopXL} {
    min-height: 648px;
  }

  a {
    height: 40px;

    @media ${device.desktopXL} {
      max-width: 429px!important;
    }
  }


`

export const Button = styled.a`
  border: 1px solid #FF7A00;
  border-radius: 8px;
  color: #FF7A00;
  font-size: 14px;
  line-height: 17px;
  font-weight: 600;
  width:  100%;
  height: 48px;
  margin-top: 16px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:focus, &:hover {
    outline: none;
    color: #FF7A00;
  }

  @media (min-width: ${breakpoints.md}){
    width: 336px;
    margin-top: 32px;

  }

  @media (min-width: ${breakpoints.lg}){
    width: 456px;
    margin-top: 32px;

  }

`
