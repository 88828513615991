import Img from 'gatsby-image'
import React from 'react'

import useWidth from 'src/hooks/window/useWidth'

import { WIDTH_MD } from 'src/utils/breakpoints'

import usePageQuery from '../../pageQuery'

import { Button, ContentHeader } from './style'

type IModalDataLayerProps = {
  sendDatalayerEvent: Function;
}

const Header = ({ sendDatalayerEvent }: IModalDataLayerProps) => {
  const width = useWidth(300)
  const data = usePageQuery()
  const title = <h1 className='fs-24 lh-30 fs-lg-32 lh-lg-40 fs-xl-40 lh-xl-50 text-grayscale--500 fw-600'><span className='d-xl-block'>Desenrola Brasil: </span> <span className='d-xl-block'>renegociação de</span> dívida no Inter sem burocracia</h1>

  return (
    <ContentHeader className='py-5 justify-content-lg-between align-items-center'>
      <div className='container'>
        <div className='row align-items-center'>
          <div className='col-12 col-md-6 col-lg-5 offset-lg-1 offset-xl-2 order-md-last'>
            {width < WIDTH_MD && title}
            <Img fluid={data.renegocieSuaDivida.fluid} alt='' />
          </div>
          <div className='col-12 col-md-6 col-xl-5'>
            {width >= WIDTH_MD && title}
            <p className='fs-16 lh-19 fs-xl-18 lh-xl-22 text-grayscale--500 fw-400 mt-3'>
              Chegou a hora de limpar seu nome com condições especiais. Aproveite o Desenrola Brasil e volte a ficar em dia no Inter.
            </p>
            <p className='fs-16 lh-19 fs-xl-18 lh-xl-22 text-grayscale--500 fw-400'>Consulte sua dívida do cartão de crédito e confira as ofertas.</p>
            <a
              onClick={() => {
                sendDatalayerEvent({
                  section: 'dobra_1',
                  element_action: 'click button',
                  element_name: 'Consultar Minhas Dívidas',
                  redirect_url: 'https://negocie.bancointer.com.br/?utm_source=banco_inter_landing',
                  section_name: 'Desenrola Brasil: renegociação de dívida no Inter sem burocracia',
                })
              }}
              href='https://negocie.bancointer.com.br/?utm_source=banco_inter_landing'
              title='Consultar Minhas Dívidas'
              target='_blank'
              className='btn btn-orange--extra text-white mw-100 rounded-2 fs-14 fs-md-16 lh-19 fw-600 text-none text-center mt-2' rel='noreferrer'
            >
              Consultar Minhas Dívidas
            </a>
            <Button
              href='https://emprestimo.inter.co/login?desenrola-brasil'
              title='Consultar Minhas Dívidas'
              target='_blank'
              onClick={() => {
                sendDatalayerEvent({
                  section: 'dobra_1',
                  element_action: 'click button',
                  element_name: 'Consultar Minhas Dívidas',
                  redirect_url: 'https://emprestimo.inter.co/login?desenrola-brasil',
                  section_name: 'Desenrola Brasil: renegociação de dívida no Inter sem burocracia',
                })
              }}
            >
              Já Negociei Minha Divida
            </Button>
          </div>
        </div>
      </div>
    </ContentHeader>
  )
}

export default Header
